/***
*
*   BILLING / PLAN
*   Update the billing plan
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Card, Form, Message, usePlans, Button } from 'components/lib';
import { Typography } from '@mui/material';
import axios from 'axios';

export function BillingPlan(props){

  const plans = usePlans();
  const context = useContext(AuthContext);
  const [activePlan, setActivePlan] = useState(null);

  useEffect(() => {
      if (plans && plans.data && plans.data.active) {
        const activePlan = plans?.data?.raw?.plans.find(plan => plan?.id === plans?.data?.raw?.active);
        setActivePlan(activePlan || null);
      }  
  }, [plans])

  const goToStripePortal = async () => {
    const res = await axios.get('/api/auth/stripe-session');
    window.location.href = res.data.stripeSessionUrl;
  }

  return (
    <div>
      <Card loading={ plans.loading || props.subscription.loading } restrictWidth className={ props.className }>
        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'end', marginBottom: 30}}>
          <Typography variant='body2'>
            Your current plan:
          </Typography>
          <Typography variant='h5' style={{marginLeft: 20, marginBottom: -4}}>
            { activePlan?.name} 
          </Typography>
          <Typography variant='body2' style={{marginLeft: 5}}>
             {activePlan?.id !== 'free' && (!activePlan?.id.startsWith('enterprise')) && (activePlan?.interval === 'month' ? ' (monthly)' : ' (yearly)')}
          </Typography>
        </span>
        { props.subscription?.data?.object && 
          <Message 
            type='info'
            title= { props.subscription.data.status === 'trialing' ? 'Trial Period' : 'Your Billing Cycle' }
            text={ props.subscription.data.status === 'trialing' ? 
              `Your account will be charged on ${props.subscription.data.object.current_period_end}` :
              `${props.subscription.data.object.current_period_start} to ${props.subscription.data.object.current_period_end}` 
              
            }
          />
        }
        {
          activePlan?.id !== 'free' && !activePlan?.id.startsWith('enterprise') &&
            <Button
              color={ props.destructive ? 'red' : 'green' }
              text={ 'Manage Plan' }
              fullWidth={true}
              action={() => goToStripePortal()}          
            />
        }
        { !activePlan?.id.startsWith('enterprise') &&
          <Typography variant='string' style={{color: '#8c8c8c', fontSize: 14, fontWeight: 'lighter'}}>
            You will soon be required to upgrade to a paid plan to continue using the service.
          </Typography>
        }

        {/* <Form
          // inputs={
            plan: {
              label: 'Your subscription plan (custom pricing available for high tiered volumes)',
              type: 'select',
              required: true,
              default: plans?.data?.active,
              options: plans?.data?.list
            }
          }}
          url='/api/account/plan'
          method='PATCH'
          buttonText='Update Plan'
          callback={(res) => {

            context.update({ plan: res.data.data.plan });
            window.location.reload();

          }}
          />  */}
        
      </Card>


      

    </div>
  )
} 