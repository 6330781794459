/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: verify email address
*   Step 3: select plan
*
**********/

import React, { useContext, useEffect, useState } from 'react';
import { Animate, AuthContext, Row, Card, PaymentForm, ViewContext, useNavigate, Event, useAPI, Button } from 'components/lib';
import { Grid, Typography, ToggleButtonGroup, ToggleButton} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function SignupPlan(props){

  const context = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  // const plans = usePlans(); // not working here
  const plansApiData = useAPI('/api/account/plans');
  const plan = window.location.hash.substring(1);
  const [onboarded, setOnboarded] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [planPeriod, setPlanPeriod] = useState('month');
  const [selectedPlan, setSelectedPlan] = useState(null); 

  // if (!plans.data)
  //   return false;

  useEffect(() => {
    if (plansApiData && plansApiData.data && plansApiData.data.plans) {
      let list = [];

      // format plans data for select input
      plansApiData.data.plans.forEach( plan => {
        if (plan.id !== 'free' && !plan.id.startsWith('enterprise')) {
          const listEntry = {
            label: `${plan.name} (${plan.currency.symbol}${plan.price}/${plan.interval})`,
            value: plan.id
          }
          list.push(listEntry);
        }
      });
      setPlanList(list);
    }
  }, [plansApiData])


  const PriceDisplay = (props) =>                 
  <div style={{marginTop: 40}}>
    <span style={{display: 'flex', flexDirection: 'row'}}>
      <Typography variant="h4" style={{display: 'inline-block'}}>
        {props.price}
      </Typography>
      <Typography variant='body1' style={{marginLeft: 10, color: '#8c8c8c', fontSize: 12, fontWeight: 'lighter', minHeight: 40, display: 'inline-block'}} >
        per <br/>
        {props.interval}
      </Typography>
    </span>
    <div style={{height:40}}>
    { props.trialPeriodDays && props.trialPeriodDays > 0 ? 
        <Typography variant='body2' style={{ color: '#EDAFCA', fontSize: 12, fontWeight: 'lighter', minHeight: 40, display: 'inline-block'}} >
          {`with risk-free ${props.trialPeriodDays} day trial`}
        </Typography>
        : 
        <></>
    }
    </div>
  </div>

  const PlanDisplay = () =>
    <section>
    <Row align='center' height='medium'>
      <div style={{justifyContent: 'center', alignContent: 'center', justifyItems:'center', alignItems:'center', minHeight:100}}>
        <ToggleButtonGroup
                value={planPeriod}
                exclusive
                onChange={(event, period) => setPlanPeriod(period)}
                aria-label="visibility"
              >
                <ToggleButton value="month" aria-label="monthly" sx={{ color: planPeriod === 'month' ? '#444444' : 'inherit' }}>
                  Monthly
                </ToggleButton>
                <ToggleButton value="year" aria-label="yearly" sx={{ color: planPeriod === 'year' ? '#444444' : 'inherit' }}>
                  Yearly
                </ToggleButton>
          </ToggleButtonGroup>       
      </div>
      </Row>
      <Grid container style={{maxWidth: 1280}} alignItems="center" justifyContent="center">

        {plansApiData?.data?.plans.filter( plan => plan.interval === planPeriod ).map( (plan, i) => {
          if (plan.id === 'free') return;
          return (
            <Grid key={`${plan.name}-${plan.interval}-container-${i}`} item xs={12} sm={6} md={4} marginTop={2}>
              <Animate>
              <div className='p-8' style={{padding:30, backgroundColor: '#ffffff', borderRadius:5, margin: 10, height:'100%', maxWidth: 300, borderWidth: 1, borderColor: '#f0f0f0'}}>
                <Typography variant='h6'>
                  {plan.name}
                </Typography>
                <Typography variant='body1' style={{color: '#8c8c8c', fontSize: 12, fontWeight: 'lighter', minHeight: 40}}>
                  {plan.description}
                </Typography>
                <PriceDisplay price={`${plan.currency.symbol}${plan.price}`} interval={plan.interval} trialPeriodDays={plan.trial_period_days}/>
                <div style={{marginTop: 20}}>
                  <Button text='Select Plan' action={() => {setSelectedPlan(plan)}} fullWidth />
                </div>
                <div style={{marginTop: 40}}>
                <Typography variant='body1' style={{color: '#232323', fontSize: 12, fontWeight: 'lighter', minHeight: 40}}>
                  This plan includes:
                </Typography>
                </div>
                {
                  plan.features.map(
                    (feature, i) => 
                    <span key={`${plan.name}-${plan.interval}-feature-${i}`} style={{display: 'flex', flexDirection: 'row'}}>
                      <CheckCircleIcon/>                  
                      <Typography variant='body1' style={{color: '#232323', fontSize: 12, fontWeight: 'lighter', minHeight: 40, marginLeft: 10}}>
                        {feature.name}
                      </Typography>
                    </span>
                  )
                }
              </div>    
              </Animate>
            </Grid>
          )
        })
        }
      </Grid>
    </section> 

  // return  navigate('/dashboard');

  const PaymentView = () => 
    <Animate type='pop'>
      <Row title={`${selectedPlan.name} Plan (${ selectedPlan.interval === 'month' ? 'Monthly' : 'Yearly'})`}>
        <Typography variant="body2" align="center" style={{color: '#94a3b8',fontWeight: 'normal'}}>
            {`${selectedPlan.currency.symbol}${selectedPlan.price} per ${selectedPlan.interval}`}
        </Typography>
        {
          selectedPlan.trial_period_days && selectedPlan.trial_period_days > 0 ? 
          <Typography variant="body2" align="center" style={{color: '#94a3b8',fontWeight: 'normal', marginTop: 20}}>                
              {`${selectedPlan.trial_period_days} day${selectedPlan.trial_period_days > 1 ? 's' : ''} risk-free trial included.  You won't be charged today.`}
          </Typography>
          : <></>
        }
        <Card loading={ false } restrictWidth center>
          <PaymentForm
            inputs={{
              plan: {
                type: 'hidden',
                value: selectedPlan.id
              },              
              token: {
                label: 'Credit Card',
                type: 'creditcard',
                required: true,
              },
              isSignUp: {
                type: 'hidden',
                value: true
              },
              trialPeriodDays: {
                type: 'hidden',
                value: selectedPlan.trial_period_days
              }

            }}
            url='/api/account/plan'
            updateOnChange
            onChange={ ignore => {}}
            method='POST'
            buttonText='Save Plan'
            callback={ res => {

              // save the plan to context, then redirect
              Event('selected_plan', { plan: res.data.plan });
              context.update({ plan: res.data.plan, subscription: res.data.subscription });
              navigate(res.data.onboarded ? '/dashboard' : '/welcome');

            }}
          />
          <div style={{marginTop: 20}}/>
          <Button
            textOnly
            action={ () => setSelectedPlan(null) }
            text='Choose Another Plan'
          />
        </Card>
      </Row>
    </Animate>
    if (selectedPlan) {
      return <PaymentView />
    }
    else {
      return(
        <Animate type='pop'>
          <Row title='Select Your Plan'>
            <PlanDisplay />
          </Row>
        </Animate>
      );
    
    }
    
}
